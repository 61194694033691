import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  BrowserRouter,
  Routes
} from "react-router-dom";
import WeWash from './components/WeWash';
import Hero from './components/Hero';
import backImage from './images/lines_start.png'
import Footer from './components/Footer';
import msgIcon from './images/msgIcon.png'

import FormRest from './components/Form';
import ImmediateOrderForm from './components/ImmediateOrderForm';
import AccesibilityText from './components/AccesibilityText';
import CustomersOrEmplyees from './components/CustomersOrEmplyees';
import global_en from './translations/en/global.json';
import global_heb from './translations/heb/global.json';
import global_tig from './translations/tig/global.json';
import global_ru from './translations/ru/global.json';
import global_ar from './translations/ar/global.json';
import global_hi from './translations/hi/global.json';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import {FaWhatsapp } from 'react-icons/fa'
import { TermsAndConditions } from './components/TermsAndConditions';
import { PrivacyPolicyHeb } from './components/TermsOfUse/PrivacyPolicyHeb';
import { DirectMailerMapPol3 } from './components/DirectMailerMapPol3';
import { DirectMailerMapPol5 } from './components/DirectMailerMapPol5';
import { DirectMailerMapPol6 } from './components/DirectMailerMapPol6';
import { Article } from './components/Article';
import { TermsOfUseStart2 } from './components/TermsOfUse/TermsOfUseStart2';
import { excludedPaths } from './utils/checkFields';
import ReactGA from "react-ga4";

import UserDeletionformEmployee from './components/UserDeletionformEmployee';
ReactGA.initialize("G-ZN91JR6X3T");

i18next.init({
  interpolation:{escapeValue:false},
  lng:"he",
  resources:{
    en:{
      global:global_en
    },
    he:{
      global:global_heb
    },
    tig:{
      global:global_tig
    },
    ru:{
      global:global_ru
    },
    ar:{
      global:global_ar
    },
    hi:{
      global:global_hi
    }
  }
})


const root = ReactDOM.createRoot(document.getElementById('root'));
const whatsappUrl = process.env.REACT_APP_WHATSAPPURL;
ReactGA.event({
  category: 'Enters',
  action: 'Enters website'
});
 


root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <>
  
  <div className='' >
    <I18nextProvider i18n={i18next}>

   
  <BrowserRouter>
  
  <img src={backImage} className='h-screen object-cover   sm:h-full sm:w-full' style={{position:'absolute',zIndex:-1000}}/>
  {/* <img src={appImage} style={{position:'absolute',zIndex:-1000,bottom:'0px',left:'0px',width:'40%'}}/> */}
    <div className=''>  
     {/* min-h-[1000px] */}
        {/* <Hero/> */}
       
        </div>
     
       {/* messsage icon */}
  
 <div className='fixed bottom-4 sm:mr-4 sm:ml-4' >
              {!excludedPaths.includes(window.location.pathname) && <FaWhatsapp size='50px' color='#25D366' onClick={()=> window.open(whatsappUrl)} alt="message icon"/>  }
  </div>
 

  <Routes>
    <Route path='/' element ={<CustomersOrEmplyees/>} />
    <Route path='/customers' element ={<App/>}>
      <Route index element = {<Hero/>}/>
    </Route>
    <Route path='/employes' element ={<App/>}>
      <Route index element = {<Hero/>}/>
    </Route>
    <Route path='/wewash' element ={<WeWash/>}>
      <Route index element = {<Hero/>}/>
    </Route>

    <Route path='/form' element ={<FormRest/>}> 
    <Route index element = {<Hero/>}/>
    </Route>
    <Route path='/article' element ={<Article/>}> 
    {/* <Route index element = {<Hero/>}/> */}
    </Route>
    <Route path='/immediateOrderForm' element ={<ImmediateOrderForm/>}/>
    <Route path='/privacypolicyheb' element ={<PrivacyPolicyHeb/>}/>
    <Route path='/AccesibilityText' element ={<AccesibilityText/>}/>
    <Route path='/DirectMailerPol3' element ={<DirectMailerMapPol3/>}/>
    <Route path='/DirectMailerPol5' element ={<DirectMailerMapPol5/>}/>
    <Route path='/DirectMailerPol6' element ={<DirectMailerMapPol6/>}/>
    <Route path='/TermsOfUseStart2' element ={<TermsOfUseStart2/>}/>
    <Route path='/TermsOfUseCustomersHeb' element ={<TermsAndConditions/>}/>
    <Route path='/TermsOfUseServicesHeb' element ={<TermsAndConditions/>}/>
    <Route path='/TermsOfUseCustomersEng' element ={<TermsAndConditions/>}/>
    <Route path='/TermsOfUseServicesEng' element ={<TermsAndConditions/>}/>
    <Route path='/PrivacyPolicyEng' element ={<TermsAndConditions/>}/>
    <Route path='/PrivacyPolicyHeb' element ={<TermsAndConditions/>}/>
    <Route path='/UserDeletionformEmployee' element ={<UserDeletionformEmployee/>}/>



  </Routes>
  
      <div>
        {!excludedPaths.includes(window.location.pathname) && <Footer /> }
      </div>
  </BrowserRouter>
  </I18nextProvider>
  
</div>
  
 </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
