import React from 'react'
import { Outlet } from "react-router-dom";
import JoinBtn from './JoinBtn';
import EmbeddedVideo from './EmbeddedVideo';
import banner from './../images/banners-2.png'
import slide from './../images/slide1.png'
import slide2 from './../images/slide2.png'
import appImage from '.././images/app_background.png'
import logo from '../images/Logo.png';
import washLogo from '../images/WASH.png'
import haim from "../images/haim.png"
import { useNavigate } from "react-router-dom";


export function Article(props) {

    let navigate = useNavigate();
    return (
        <div>
            {/* <Outlet/> */}

            <div className='hidden lg:block lg:hidden 2xl:block xl:hidden ' style={{ overflow: 'hidden' }}>
                <img className={` xl:max-w-[32%] 2xl:max-w-[28%]`} src={appImage} style={{ position: 'absolute', zIndex: -1000, bottom: '0px', left: '0px' }} />
            </div>

            <div className='ml-4 sm:ml-4 mr-4 sm:mr-4  xl:mr-64 main-slogan text-base sm:text-2xl sm:max-w-55 text-right pb-10'>
                <div className='pt-14 sm:pt-14'>
                    <p><span onClick={() => { navigate('/') }} className='underline underline-offset-2 cursor-pointer'>בית</span> / <span>חדשות</span> / <span>המהפכה הדיגיטלית</span></p>
                </div>
                <div className='2xl:w-60 w-[110px] pt-10 sm:pt-10   w-36  sm:w-80  m-auto sm:m-1'
                    style={{ minWidth: '107px' }}>
                    <img className='' src={logo} alt='logo' />

                </div>
                {/* <div className='w-1/2 mt-2 w-[110px] mb-12 sm:w-80 w-36 2xl:w-60  sm:mt-1 m-auto sm:m-1'>
                    <img src={washLogo} alt='logo' /> </div> */}
                <div className='mt-10 mb-10'>
                    <h1 className='text-5xl'>
                        המהפכה הדיגיטלית בהעסקת עובדים זרים יוצאת לדרך!
                    </h1>
                </div>


                <p className='mb-4 text-black font-[600] '>
                    חברת כוח אדם דיגיטלית תשמור באמצעות פיתוח טכנולוגי חדש על זכויות
                    העובדים הזרים בישראל ותתווך בינם לבין המעסיקים ללא מעורבות של
                    חברות כוח אדם מסורתיות.
                </p>
                <p className='mb-4 text-black font-[600]'>
                    הראשון ליהנות מהפיתוח החדש הוא ענף המסעדנות בישראל.
                </p>

                <p className='mb-4 text-[#1F1546]'>
                    שוק העובדים הזרים מונה כ- 100 אלף עובדים בישראל ומגלגל תשלום
                    משכורות על סך כ12- מיליארד ₪ בשנה.
                </p>
                <p className='mb-4 text-[#1F1546]'>
                    קהילת העובדים הזרים בישראל היא קהילה של עובדים שנדדו מארץ מולדתם
                    בחיפוש אחר פרנסתם ונחשבים לכוח עבודה חרוץ, מיידי, המוכן לעבוד
                    בעבודות הלואו טק שהעובד הישראלי הממוצע לא מוכן לבצע מזה שנים רבות.
                </p>
                <p className='mb-4 text-[#1F1546]'>
                    המעסיקים הישראלים ניצלו לא פעם את אותם עובדים זרים באי תשלום
                    תנאים סוציאליים החלים עליהם, מהחלטת ממשלה(משנת 2011) שכללה מתן
                    זכויות מלאות לעובדים הזרים.
                </p>
                <p className='mb-4 text-[#1F1546]'>
                    כמו כן, הממשלה דאגה להטיל היטלים בגין העסקתם, מה שיצר למעסיק
                    הישראלי עלות שעת עבודה גבוהה מאוד שבסופו של דבר הקשתה עליו לשאת
                    בנטל העלויות. דבר אשר גרר בתחילה שרשרת של תביעות בין המעסיקים
                    לעובדים הזרים.

                </p>
                <p className='mb-4 text-[#1F1546]'>בשנים האחרונות, רוב סיום העסקתם של העובדים הזרים מסתיים בתביעות
                    כנגד המעסיק.</p>
                <p className='mb-4 text-[#1F1546] font-[600]'>בשנת 2020 נפתחו 135 תיקים כנגד מעסיקי עובדים זרים בסך קנסות כולל
                    של למעלה מ4- מיליון ₪. בגין העסקה שלא כדין נרשמו 100 קנסות בסכום
                    הקרוב למיליון שקלים.</p>
                <div >
                    <div className='mb-6 mt-10'>
                        <img className="object-contain" src={slide} alt="slide"></img>

                    </div>
                    <div className='mt-10 mb-4'>
                        <img className="object-contain" src={slide2} alt="slide1"></img>

                    </div>
                </div>
                <p className='mb-4 text-[#1F1546]'>חברות כוח אדם זיהו בשנים האחרונות את הוואקום שנוצר בין המעסיק לעובד
                    הזר ולקחו על עצמן את הסיכון בהעסקת העובד מול אותו מעסיק. את הרווח
                    שלהן הן גזרו מגזילת הזכויות הפנסיוניות שהמעסיק אמור להפריש לעובד מדי
                    חודש. בסוף תהליך ההעסקה גילו העובדים כי הכסף שהופרש לטובת התנאים
                    הסוציאליים שלהם נלקח כרווח לחברת כוח האדם.</p>
                <p className='mb-4 text-[#1F1546] font-[600]'>למה זה עוד יותר בעייתי כשמדובר בעובדים זרים?</p>
                <p className='mb-4 text-[#1F1546]'>שפה זרה, בורות, חוסר התמצאות בזכויות, פחד והישרדות יומיומית. כמו
                    פטריות אחרי הגשם צצו בשנים האחרונות עשרות משרדי עורכי דין שהתמקמו
                    במתחם התחנה המרכזית בתל אביב וזיהו את פוטנציאל הרווח מאותו עוול
                    שנגרם לעובדים. אותם עורכי דין מדרבנים את העובדים הזרים להגיש תביעה
                    נגד המעסיק ופה מתחילה שרשרת אי האמון בין כל הצדדים כי כשמגיע יום
                    הדין בבית המשפט, המעסיק בסופו של יום משלם את כל הזכויות שנגזלו ממנו
                    במשך כל תקופת העסקתו של העובד בגין שעות נוספות ,ימי חופשה ,ימי
                    הבראה ,נסיעות ועוד.</p>
                <p className='mb-4 text-[#1F1546]'>מספר יזמים מקומיים שזיהו את הצורך של קהילת העובדים הזרים בישראל
                    ולקחו על עצמם לבסס מערכת אמון בין העובד הזר למעסיק הישראלי. האמצעי
                    פלטפורמה חדשה שבאה לתת מענה לבעיות של העסקת העובדים הזרים - הן
                    מצד המעסיק והן מצד העובד.</p>
                <p className='mb-4 text-[#1F1546]'>מי שמוביל את היוזמה ועומד בראשה הוא היזם חיים פנחס אשר בעברו הקים
                    וניהל למעלה מ-25 מועדוני לילה מהמובילים במדינה.</p>
                <div className='mt-6 mb-6 max-w-[400px] m-auto'>
                    <img className="object-contain" src={haim} alt="haim" />
                    <div className='m-auto'>
                        <span>חיים פנחס</span>
                    </div>
                </div>


                <p className='mb-4 text-[#1F1546]'>
                    פנחס בחזונו מבטיח
                    שהפיתוח החדש יהווה בסיס לבניית אמון בין העובד הזר והמעסיק הישראלי.&quot;
                    לאורך כל הדרך שמנו לנגד עינינו כי העובד הינו הלקוח של החברה. היות
                    והעובד הינו עובד זר, דאגנו לתת מענה דרך משאבי אנוש של החברה לכל
                    צרכיו של העובד מעבר לצרכיו התעסוקתיים, תוך הקפדה על חיבור עם
                    הקהילה.</p>
                <p className='mb-4 text-[#1F1546]'>
                    הפלטפורמה, מבטיחים היזמים, מתאימה למבחר רחב של מקצועות
                    הלואו-טק. כגון: שוטפי כלים , טבחים , עוזרי טבח , עובדי ניקיון, מחסנאים,
                    ועובדים לתעשייה קלה, והיא מנגישה מבחר גדול של משרות ומתווכת בין
                    מבקש השירות לבין העובדים הזרים, שכבר נכוו בעבר מהמעסיק הישראלי&quot;,
                    אומר מנהל הפיתוח של START2 תמיר קונורטוב. &quot;לאורך כל תהליך
                    הפיתוח עשינו סיעור מוחות עם פאנלים של עובדים זרים והמכשול איתו
                    נאלצנו להתמודד היה מכשול החשדנות, שמנע מאותם עובדים זרים לשתף
                    פעולה עם הפלטפורמה&quot;. למה נדרש פתרון דיגיטלי שיעשה סדר? מי נהנה
                    ממנו ואיך?</p>
                <p className='mb-4 text-[#1F1546]'>המעבר להתנהלות דיגיטלית הופך את העסקתו של העובד הזר להעסקה ישירה
                    ושקופה שמראה בתום כל משמרת את פריט מרכיבי שעת העבודה של העובד
                    מול אותו מעסיק- מהעלות הבסיסית של שעת עבודה ועד מה שנקרא נטו
                    וברוטו.</p>
                <p className='mb-4 text-[#1F1546]'>הפיתוח החדש של הסדרן הדיגיטלי יודע לתת מענה להנגשת עשרות משרות
                    בתחומים שונים תוך הקפדה על מקסימום השעות שמותר לתת מבלי לפגוע
                    במעסיק, וזאת תוך יצירת הפסקה של שלוש שעות בין נקודה לנקודה אשר
                    מאפסת את השעות הנוספות שאותו עובד היה אמור לקבל.</p>
                <div>
                    <img className="object-contain" src={banner} alt="apps"></img>
                </div>
                <p className='mb-4 text-[#1F1546] font-[600] mt-4'>יחד עם זאת לא תימנע מהעובד הזכות לעבוד 12 שעות ביממה, אם יבחר בכך.</p>
                <p className='mb-4 text-[#1F1546]'>בעולם הישן, אופרציה של ניוד ושינוע עובד ממקום למקום על מנת לחסוך את
                    השעות הנוספות היא מאוד יקרה מבחינת כוח האדם שהיה אמור להתעסק בזה,
                    וכאן יש חיסכון של הון אנושי - מה שמונע את גזילת הזכויות של העובד
                    כתחליף לרווח של חברות כוח האדם.</p>
                <p className='mb-4 text-[#1F1546]'>START2 מאפשרת לעסק לאתר את העובדים שנמצאים במרחק הקצר ביותר
                    מהמסעדה. כאשר המסעדה יודעת מי העובד שאמור להגיע אליה, מקבלת עליו
                    רקע אישי, נעשה תיאום ציפיות בלחיצת כפתור בין דרישות המעסיק לבין
                    העובד, מה שמונע חיכוכים במהלך המשמרת. השכר לעובד ישולם לו
                    באמצעות האפליקציה כולל תנאים פנסיונים מלאים על פי חוק. בנוסף
                    האפליקציה תרכז את שעות העבודה באמצעות שעון עבודה אשר הפעלתו
                    נעשית על ידי בית העסק והעובד בו זמנית עם כניסתו לעבודה ובסיום
                    המשמרת והכל בשקיפות מלאה. עד היום הושקעו בפלטפורמה כ-1.5 מיליון
                    דולר לטובת עלויות הפיתוח והתפעול שלהחברה עד למוכנות לעליה לאוויר
                    עם פלטפורמת START2 וגיוס לפי עובדים ולקוחות בישראל.</p>



                <div className='mx-4'>
                    <div className='flex justify-start nameOfService text-lg sm:mb-6 mb-6 sm:text-5xl sm:mt-[50px] mt-[50px]'>
                        <span>
                            סרטון מסעדנים:
                        </span>
                    </div>
                    <div className='max-w-[1360px]'>
                        <div>
                            {/* <img src={video} alt=""/> */}
                            <iframe src='https://www.youtube.com/embed/zc51uOb35wM?si=ZQHdROXjbR-Kn3s5' 
                            className='w-full aspect-video' 
                            style={{ borderRadius: '24px' }}></iframe>
                        </div>

                    </div>
                    <p className='mb-6 mt-6 text-[#1F1546] underline underline-offset-2'>
                        ניתן להירשם כבר כעת באתר באזורים בהם פועל השירות.
                    </p>
                    <div className='flex'>
                        <div className="py-4 px-8  rounded-full cursor-pointer" style={{ backgroundColor: 'rgb(31, 21, 70)' }}
                            onClick={() => { navigate('/customers'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}
                        >
                            <span style={{ color: 'white' }}>
                                לפרטים נוספים והרשמה
                            </span>

                        </div>

                    </div>
                </div>


                <div>
                </div>


                {/* <JoinBtn text='הצטרף עכשיו' linkUrl='www.start2.co.il' showQuestion={false}/> */}
            </div>
        </div>
    )
}
