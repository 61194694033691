import { useTranslation } from 'react-i18next';
import {FlagIcon} from 'react-flag-kit'


export default function LanguagesBtns(props) {
    

    
    
    return (
      <div >
        <div className={`font-Monserrat flex gap-x-2 font-semibold text-1xl sm:text-2xl absolute top-8 left-8`}
        style={{color:'#FA8416'}}>
        <div><button onClick={()=>props.handleChangeLanguage("he")}><FlagIcon code="IL" size={32}/></button></div>
        <div><button onClick={()=>props.handleChangeLanguage("en")}><FlagIcon code="US" size={32}/></button></div>
        <div><button onClick={()=>props.handleChangeLanguage("tig")}><FlagIcon code="ER" size={32}/></button></div>
        <div><button onClick={()=>props.handleChangeLanguage("ru")}><FlagIcon code="RU" size={32}/></button></div>
        <div><button onClick={()=>props.handleChangeLanguage("ar")}><FlagIcon code="SD" size={32}/></button></div>
        <div><button onClick={()=>props.handleChangeLanguage("hi")}><FlagIcon code="IN" size={32}/></button></div>
        </div>
        
      </div>
    );
  }